@import (reference) '../../styles/mixins.less';

.select-coupon-dialog {
  :global {
    .ant-modal-content {
      width: 891px;
      height: 664px;
    }
    // .ant-modal-body {
    //   height: 490px;
    // }
  }

  .refresh-tip {
    font-weight: 400;
    font-size: 13px;
    color: #8c8c8c;
  }
}
