@import (reference) '../../styles/mixins.less';

.select-user-dialog {
  :global {
    .ant-modal-content {
      width: 772px;
      height: 593px;
    }
    .ant-modal-body {
      height: 429px;
    }
  }

  .status {
    border-radius: 2px;
    display: inline-block;
    padding: 0 8px;
    color: #595959;
    border: 1px solid #8bc7ff;
    &.green {
      background: #f6fbff;
    }
    &.red {
      border: 1px solid #f5222d;
      background: #fff1f0;
    }
  }
}
