@import '@/styles/mixins.less';

html,
body {
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: white;
  .normalize();

  * {
    .prettier-scroll-bar;
  }
}

#root {
  .size(100%);
}

.ant-btn {
  border-radius: 4px;
  height: 36px;
}

.ant-alert {
  align-items: flex-start;
  .ant-alert-icon {
    margin-top: 4px;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    padding: 12px;
  }
}

.ant-select {
  height: 36px;
  .ant-select-selector {
    border-radius: 4px;
  }
}
