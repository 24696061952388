@import (reference) '../../styles/mixins.less';

.message-card-item {
  &.text {
    width: 148px;
    border-radius: 4px;
    background: #fff;
    padding: 8px 13px;
    word-break: break-all;
    white-space: pre-wrap;
    font-size: 13px;
  }

  &.image {
    width: 140px;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    cursor: pointer;

    > img {
      width: 100%;
    }
  }

  &.link {
    width: 168px;
    border-radius: 4px;
    background: #fff;
    padding: 8px 13px;
    cursor: pointer;
    border: 1px solid #eee;

    > .title {
      .t-ellipses;
      font-size: 12px;
      font-weight: 600;
      color: #333333;
      width: unset !important;
      text-align: left;
    }

    .link-inner {
      display: flex;
      margin-top: 4px;

      > .desc {
        flex: 1;
        .t-m-ellipses(2);
        height: 35px;
        font-size: 12px;
        color: #666666;
      }

      > img {
        margin-right: 6px;
        object-fit: cover;
        .size(40px);
      }
    }
  }

  &.video {
    position: relative;
    > video {
      object-fit: fill;
      width: 140px;
      height: 89px;
      border-radius: 4px;
    }
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      margin-top: -12px;
    }
  }

  &.text {
    width: 148px;
    border-radius: 4px;
  }

  &.tiny {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 180px;
    min-height: 148px;
    background: #ffffff;
    border-radius: 4px;

    .title {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        width: 100%;
        .t-ellipses;
        font-weight: 600;
        font-size: 13px;
        color: #333333;
      }

      .desc {
        font-weight: 400;
        max-height: 35px;
        min-height: 17px;
        .t-m-ellipses(2);
        font-size: 12px;
        color: #666666;
        line-height: 17px;
        text-align: justify;
        font-style: normal;
      }
    }

    .image {
      width: 164px;
      height: 108px;
      margin-top: 10px;
      object-fit: cover;
    }
  }

  &.coupon {
    width: 181px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #efefef;

    &.coupon-with-more-setting {
      height: 100%;
      background: linear-gradient(205deg, #fed4d5 0%, #fff6f6 22%, #ffffff 100%);

      .coupon-content {
        position: relative;
        top: -8px;
      }

      .coupon-long-introduce {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 13px;
      height: 20px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 8px;
      span {
        width: 162px;
        .t-ellipses;
      }

      img {
        width: 17px;
        margin-right: 4px;
      }
    }

    .coupon-inner {
      .coupon-image {
        width: 100%;
        height: 108px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .coupon-content {
      width: 100%;
      height: 45px;
      background: linear-gradient(110deg, #fc562a 0%, #fa0d37 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 8px;

      .coupon-left {
        border-radius: 8px;
        font-size: 19px;
        color: #fff;
        font-weight: 600;

        .discount {
          font-size: 19px;
          color: #fff;
          font-weight: 600;
        }

        .unit {
          font-size: 12px;
          color: #fff;
          font-weight: 400;
        }
      }

      .coupon-right {
        flex: 1;
        margin-left: 9px;
        display: flex;
        align-items: center;

        .coupon-title {
          font-weight: 600;
          font-size: 10px;
          color: #ffe2c1;
          .t-ellipses;
          width: 76px;
        }

        .coupon-desc {
          width: 76px;
          font-weight: 400;
          font-size: 9px;
          color: #ffe2c1;
          .t-ellipses;
        }

        .coupon-right-button {
          width: 36px;
          height: 17px;
          background: linear-gradient(90deg, #fdeedf 0%, #fbdbc2 100%);
          border-radius: 9px;
          font-weight: 600;
          font-size: 9px;
          line-height: initial;
          margin-left: 4px;
          margin-right: 4px;
          color: #fa1336;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
