@import (reference) '../../styles/mixins.less';

.staff-tag {
  max-width: 136px;
  padding: 3px 7px;
  display: inline-flex;
  background: #ffffff;
  border: @default-border-1;
  border-radius: 2px;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 6px;

  .name {
    margin-left: 4px;
    flex-shrink: 0;
  }

  .close-icon {
    margin-left: 4px;
    padding: 6px;
    padding-right: 0;
    font-size: 12px;
    cursor: pointer;
  }

  &.disable {
    background: rgba(0, 0, 0, 0.04);
  }
}
