@import (reference) '../../styles/mixins.less';

.auto-send-detail-drawer {
  :global {
    .ant-descriptions-item-label {
      width: 90px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .group-nest-info-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .message-content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 428px;
    background: #fafafa;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    padding: 12px 16px;
    gap: 16px;

    .message-item {
      display: flex;
      align-items: flex-start;

      .message-item-label {
        margin-right: 12px;
        color: #595959;
      }
    }
  }
}
