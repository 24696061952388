@import (reference) '../../styles/mixins.less';

.attachments-selection-dialog {
  :global {
    .ant-form {
      padding-right: 50px;
    }

    .ant-form-item {
      width: 100%;

      .ant-form-item-label {
        width: 90px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
