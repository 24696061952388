@import (reference) '../../styles/mixins.less';

.import-excel-dialog {
  .description {
    width: 100%;
    margin-bottom: 16px;
  }

  :global {
    .ant-upload-wrapper {
      .ant-upload-drag {
        height: 240px;
        &.ant-upload-drag-hover {
          border-color: @color-primary-hover;
        }
      }
    }

    .ant-upload-drag-container {
      vertical-align: top !important;
      padding-top: 46px;
    }
  }

  .upload-icon {
    margin-bottom: 16px;
    img {
      .size(56px);
    }
  }

  .upload-text,
  .upload-hint {
    font-weight: 400;
    font-size: 13px;
    color: #8c8c8c;
  }
}
