@import (reference) '../../styles/mixins.less';

.select-group-nest-dialog {
  :global {
    .ant-modal-content {
      width: 702px;
      height: 611px;
    }
    .ant-modal-body {
      height: 448px;
    }
  }

  .container {
    display: flex;
    height: 100%;
    .left-container {
      width: 50%;
      display: flex;
      flex-direction: column;

      .search-input {
        margin-bottom: 8px;
      }

      .group-list-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        padding-right: 8px;

        .group-list {
          flex: 1;
          min-height: 0;
          overflow: auto;

          .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }

          .group-item {
            padding: 6px 0;
          }
        }
      }
    }

    .divider {
      height: calc(100% - 40px);
      margin: 0;
      position: relative;
      top: 40px;
    }

    .right-container {
      width: 50%;
      padding-left: 32px;
      display: flex;
      flex-direction: column;

      .selected-info {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .selectedGroupList {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      overflow: auto;
      padding-right: 8px;

      .selected-group-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 0;

        .close-icon {
          cursor: pointer;
          font-size: 12px;
          color: #8c8c8c;
          user-select: none;
        }
      }
    }

    .group-item-content {
      display: flex;
      align-items: center;

      img {
        width: 24px;
      }

      .group-item-content-name {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        .group-item-content-name-text {
          font-weight: 400;
          user-select: auto;
          font-size: 14px;
          color: #262626;
        }
        .group-item-content-name-count {
          font-weight: 400;
          font-size: 13px;
          color: #8c8c8c;
        }
      }
    }
  }
}
