@import (reference) '../../styles/mixins.less';

.join-group-gift-detail-drawer {
  :global {
    .ant-drawer-body {
      padding: 16px;
      background: #f4f6fb;
    }
  }
  .main {
    .size(100%);
    background: #fff;
    padding: 24px;

    .header {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      .title {
        font-weight: 600;
        font-size: 15px;
        line-height: initial;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;

        .icon {
          font-size: 24px;
          color: #333333;
          margin-right: 8px;
          position: relative;
          top: -2px;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        .card-item-box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: linear-gradient(180deg, #f6f6ff 0%, #fcfdff 51%, #ffffff 100%);
          box-shadow: inset 0px -1px 2px 0px rgba(159, 161, 255, 0.5);
          border-radius: 8px;
          .card-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 142px;
            height: 107px;

            .card-item-title {
              font-weight: 600;
              font-size: 32px;
              color: #3e4567;
              margin-bottom: 4px;

              .card-item-title-unit {
                font-weight: 400;
                font-size: 12px;
                color: #818693;
              }
            }
            .card-item-desc {
              font-weight: 400;
              font-size: 12px;
              color: #818693;
              display: flex;
              align-items: center;

              .card-item-desc-dot {
                display: inline-block;
                width: 5px;
                height: 5px;
                position: relative;
                top: 1px;
                margin-right: 4px;
                border-radius: 50%;

                &.card-item-desc-dot-green {
                  background: #a6e388;
                }
                &.card-item-desc-dot-orange {
                  background: #ffce6e;
                }
                &.card-item-desc-dot-blue {
                  background: #82c2ff;
                }
                &.card-item-desc-dot-red {
                  background: #ff9197;
                }
                &.card-item-desc-dot-gray {
                  background: #c9c9c9;
                }
              }
            }
          }
        }
      }
    }

    .line {
      width: 100%;
      height: 6px;
      background: #f4f6fb;
      margin: 24px 0;
    }

    .content {
      .content-title {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .content-title-desc {
          font-weight: 400;
          font-size: 13px;
          color: #8c8c8c;
          margin-left: 12px;
        }
      }

      .table-coupon-config {
        display: flex;
        align-items: center;
        gap: 4px;
        img {
          width: 14px;
        }
        span {
          font-weight: 400;
          font-size: 13px;
          color: #595959;
        }
      }
    }
  }
}
