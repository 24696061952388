@import (reference) '../../styles/mixins.less';

.add-group-admin-dialog {
  :global {
    .ant-form-item-label {
      width: 110px;
      text-align: right;
    }
  }
}
