@import (reference) '../../styles/mixins.less';

.guide-component {
  height: 100%;
  width: 100%;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .guide {
    display: flex;
    flex-direction: column;
    padding-top: 68px;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 22px;
      color: #343541;
      text-align: center;
      margin-bottom: 44px;
    }

    .main-image {
      width: 299px;
      margin-bottom: 40px;
    }

    .main-btn {
      width: 138px;
      height: 44px;
      background: #507bff;
      border-radius: 4px;
      margin-bottom: 96px;
    }

    .sub-list {
      display: flex;
      align-items: center;
      width: 1040px;
      height: 176px;
      background: rgba(80, 123, 255, 0.03);
      border-radius: 4px;
      border: 1px solid rgba(80, 123, 255, 0.08);
      .sub-item {
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 40px;
        padding-right: 80px;

        .sub-item-title {
          font-weight: 600;
          font-size: 15px;
          color: #507bff;
          position: relative;
          z-index: 1;
          margin-bottom: 12px;
          .sub-item-bg {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 99px;
            z-index: -1;
          }
        }
        .sub-item-desc {
          font-weight: 400;
          font-size: 13px;
          color: #4f619b;
        }
      }
    }
  }
}
