@import (reference) '../../styles/mixins.less';

.dialog {
  :global {
    .ant-modal-content {
      border-radius: 8px;
      padding: 0;
      padding: 8px 0;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: 16px 32px;
      border-bottom: 1px solid #f0f0ef;
      margin-bottom: 20px;

      .ant-modal-title {
        font-weight: 600;
        font-size: 18px;
        color: #262626;
        line-height: 24px;
      }
    }

    .ant-modal-body {
      padding: 0 32px;
    }

    .ant-modal-footer {
      border-top: none;
      padding: 10px 32px;
      padding-bottom: 24px;
    }
  }
}
