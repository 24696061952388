@import (reference) '../../styles/mixins.less';

.descriptions-title {
  :global {
    .ant-descriptions-title {
      display: flex;
      align-items: center;
      line-height: initial;
    }

    .ant-descriptions-item-label {
      width: 120px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
