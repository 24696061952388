@import (reference) '../../styles/mixins.less';

.upload-file-box {
  .image-tips {
    font-size: 12px;
    color: #999;
  }
  .upload-box {
    min-height: 110px;
  }
  .upload {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);

    svg {
      margin-bottom: 12px;
      font-size: 20px;
    }
  }
}
