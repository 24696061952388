@import (reference) '../../styles/mixins.less';

.dashboard-layout {
  .logo {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .sidebar {
    height: 100vh;

    :global {
      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
      }
    }
    .menu {
      flex: 1;
    }

    .footer {
      width: 100%;
      height: 73px;
      display: flex;
      align-items: center;
      background: #ffffff;
      padding-left: 16px;
      border: 1px solid #f6f6f6;
      cursor: pointer;
      padding-right: 8px;
      .user {
        width: 100%;
        .avatar {
          margin-right: 12px;
        }
      }
    }
  }
  .layout-content {
    background: #f4f6fb;
    padding: 24px;
    padding-top: 16px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    position: relative;

    .title {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px;
      .name {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.88);
        cursor: pointer;
        svg {
          margin-right: 8px;
        }
      }
    }

    .main {
      background: #fff;
      min-width: 1150px;
      padding: 16px 32px;
      flex: 1;
    }
    .spin-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      background: #fff;
      z-index: 100;
      opacity: 0.5;
      left: 0;
      top: 0;
    }
  }
}
.logout {
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
