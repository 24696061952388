@import (reference) '../../styles/mixins.less';

.add-member-list {
  display: flex;
  margin-top: 4px;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 12px;

  li {
    position: relative;
    .delete-icon {
      position: absolute;
      right: -10px;
      top: -5px;
      cursor: pointer;
    }

    .group-nest-item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 128px;
      height: 43px;
      background: #f7f9ff;
      border-radius: 4px;
      border: 1px solid rgba(80, 123, 255, 0.12);
      padding-left: 12px;
      padding-right: 12px;
      position: relative;
      user-select: none;

      img {
        width: 24px;
      }
      .group-nest-item-content-name {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;

        .group-nest-item-content-name-text {
          font-weight: 400;
          user-select: auto;
          font-size: 13px;
          color: #262626;
          .t-ellipses();
          max-width: 70px;
          line-height: initial;
        }
        .group-nest-item-content-name-count {
          font-weight: 400;
          font-size: 12px;
          color: #8c8c8c;
          line-height: initial;
        }
      }

      .close-icon {
        cursor: pointer;
        color: #bab9b9;
        background: #fff;
        position: absolute;
        right: -6px;
        top: -6px;
      }
    }

    .coupon-item {
      display: flex;
      align-items: center;
      max-width: 180px;
      height: 36px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      padding: 0 8px;
      padding-right: 26px;
      position: relative;

      .coupon-icon {
        width: 14px;
        margin-right: 4px;
      }

      .coupon-name {
        font-size: 13px;
        color: #262626;
        .t-ellipses();
        max-width: 140px;
      }

      .close-icon {
        margin-left: 4px;
        position: absolute;
        right: 8px;
      }
    }
  }
}

.button-bottom-tips {
  margin-top: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
